<template>
	<div class="wrapper background-adults">
		<div class="error404 ">
			<h1>Errore 404</h1>
			<h2>Indirizzo non trovato :(</h2>
		</div>
	</div>
</template>

<script>
export default {
	mounted() {
		console.log("404");
	},
};
</script>

<style lang="scss" scoped>
.wrapper {
	position: relative;
	min-height: 100vh;
}
.error404 {
	padding: 25px;
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
}
h2 {
	font-weight: normal;
}

@media only screen and (max-width: 550px) {
	.error404 {
		display: inline-block;
		width: 90%;
	}
}
</style>
